import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

const RecentBlogPosts = () => {
  const data = useStaticQuery(graphql`
    query recentBlogPosts {
      allMdx(
        limit: 2
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: {
          fields: { collection: { eq: "blog" } }
          frontmatter: { draft: { ne: true } }
        }
      ) {
        edges {
          node {
            ...MdxContent
          }
        }
      }
    }
  `);

  return (
    <ul>
      {data.allMdx.edges.map(({ node: page }) => (
        <li key={page.id}>
          <Link to={page.frontmatter.path}>{page.frontmatter.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default RecentBlogPosts;
