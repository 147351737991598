/** @jsx jsx */
import { css, jsx } from '@emotion/react';
// eslint-disable-next-line
import React from 'react';
import { Link } from 'gatsby';
import { contentArea } from '../constants';

import devresultsLogo from '../images/devresults.logo.png';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import FeaturedProjects from '../components/FeaturedProjects';
import RecentBlogPosts from '../components/RecentBlogPosts';

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <div
        css={css`
          ${contentArea}
          display: grid;
          grid-template-columns: 1fr;

          @media (min-width: 960px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas:
              'hero hero hero'
              'first second third';
          }

          grid-gap: 12px;
        `}
      >
        <div
          css={css`
            @media (min-width: 960px) {
              grid-area: hero;
            }
          `}
        >
          <h1>Ahoy there!</h1>
          <p>
            Hi! I'm Brent, a software developer and general nerd that calls
            western Pennsylvania home. I work at{' '}
            <a href="https://www.devresults.com" title="DevResults">
              DevResults
            </a>{' '}
            .
          </p>
        </div>
        <section
          css={css`
            ${card}
            @media (min-width: 960px) {
              grid-area: first;
            }
          `}
        >
          <div>
            <h3>Blog</h3>
            <RecentBlogPosts />
          </div>
          <Link to="/blog" className="small-link">
            More posts
          </Link>
        </section>
        <section
          css={css`
            ${card}
            @media (min-width: 960px) {
              grid-area: second;
            }
          `}
        >
          <div>
            <h3>Projects</h3>
            <FeaturedProjects />
          </div>
          <Link to="/projects" className="small-link">
            More projects
          </Link>
        </section>
        <section
          css={css`
            ${card}
            @media (min-width: 960px) {
              grid-area: third;
            }
          `}
        >
          <div>
            <h3>Work</h3>
            <div
              css={css`
                display: flex;
              `}
            >
              <a
                href="https://www.devresults.com"
                title="DevResults"
                css={imageLink}
              >
                <img src={devresultsLogo} alt="DevResults logo" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;

const card = css`
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .small-link {
    font-size: 0.9rem;
  }
`;

const imageLink = css`
  display: inline-block;
`;
