import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

const FeaturedProjects = () => {
  const data = useStaticQuery(graphql`
    query featuredProjects {
      allMdx(
        limit: 2
        sort: { fields: [frontmatter___title] }
        filter: {
          fields: { collection: { eq: "projects" } }
          frontmatter: { draft: { ne: true }, featured: { eq: true } }
        }
      ) {
        edges {
          node {
            ...MdxContent
          }
        }
      }
    }
  `);

  return (
    <ul>
      {data.allMdx.edges.map(({ node: page }) => (
        <li key={page.id}>
          <Link to={page.frontmatter.path}>{page.frontmatter.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FeaturedProjects;
